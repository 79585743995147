import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export const Notification = (props) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={props.notifyOpen}
    autoHideDuration={6000}
    onClose={props.handleCloseNotify}
  >
    <Alert onClose={props.handleOpenNotify} severity={props.notifySevere}>
      {props.notifyMessage}
    </Alert>
  </Snackbar>
);

export default Notification;
