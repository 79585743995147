import React, { useEffect, useState } from 'react';
import {
  Button, CardContent, Container, Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import * as passwordHash from 'password-hash';
import Cookies from 'js-cookie';
import StringConstants from '../resources/StringConstants';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const res = props.users;

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  useEffect(() => {
    props.fetchUser();
    const username = Cookies.get('username');
    const password = Cookies.get('password');
    const is_super = Cookies.get('is_super');
    if (username && password && is_super) {
      props.setMainUser({
        username,
        password,
        is_super,
      });
      props.setAuth(true);
    }
  }, [props.fetchUser]);

  function validateCredentials() {
    // Extract just one user with correct credentials
    const validated_user = res.filter(
      (row) => row.username === username && passwordHash.verify(password, row.password),
    )[0];

    if (validated_user) {
      props.setMainUser(validated_user);
      setUsername('');
      setPassword('');
      Cookies.set('username', validated_user.username, {
        secure: true,
        expires: 365,
      });
      Cookies.set('password', validated_user.password, {
        secure: true,
        expires: 365,
      });
      Cookies.set('is_super', validated_user.is_super, {
        secure: true,
        expires: 365,
      });

      props.setAuth(true);
    } else {
      alert(StringConstants.WRONG_USER_OR_PASSWORD);
      setPassword('');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    try {
      validateCredentials();
    } catch (e) {
      alert('เกิดข้อผิดพลาด โปรดตรวจสอบการเชื่อมอินเทอร์เน็ต');
      console.error(e);
    }
  }

  return (
    <div className="login-container">
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid
                  item
                  xs={12}
                  style={{ marginBottom: 20, textAlign: 'center' }}
                >
                  <Typography variant="h3">
                    {StringConstants.PLEASE_LOGIN}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    type="text"
                    name="email"
                    label={StringConstants.USERNAME}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    name="password"
                    label={StringConstants.PASSWORD}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                block
                variant="contained"
                color="primary"
                type="submit"
                className="form-button"
                disabled={!validateForm()}
                fullWidth
              >
                {StringConstants.LOGIN}
              </Button>
            </CardActions>
          </Card>
        </form>
      </Container>
    </div>
  );
};

export default Login;
