import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'fontsource-roboto';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { thTH } from '@material-ui/core/locale';

const theme = createMuiTheme(
  {
    overrides: {
      MuiContainer: {
        root: {
          paddingLeft: '10px',
          paddingRight: '10px',
          '@media (min-width: 600px)': {
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        },
      },
      MuiTableCell: {
        sizeSmall: {
          // padding: '1px 12px 1px 8px',
          padding: '4px 14px 4px 8px',
          maxWidth: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          // width: '1%',
        },
      },
    },
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  thTH,
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
);
