/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import StringConstants from '../resources/StringConstants';

export default function IDFilterBox(props) {
  const { fromID, toID, setFromID, setToID, searchByID } = props;

  const handleFromIDChange = (event) => {
    setFromID(event.target.value);
  };

  const handleToIDChange = (event) => {
    setToID(event.target.value);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        searchByID();
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label={props.labelFromID}
                value={fromID || ''}
                margin="dense"
                onChange={handleFromIDChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                placeholder={props.labelFromID}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={props.labelToID}
                value={toID || ''}
                margin="dense"
                onChange={handleToIDChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                placeholder={props.labelToID}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={StringConstants.SEARCH}>
            <IconButton type="submit" color="primary">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
