import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as passwordHash from 'password-hash';
import StringConstants from '../resources/StringConstants';
import { Transition } from '../forms/DialogConfig';
import UserTable from '../tables/UserTable';
import CreateUserForm from '../forms/CreateUserForm';
import EditUserForm from '../forms/EditUserForm';

const UserDashboard = (props) => {
  const { dashboardIsOpen, closeDashboard } = props;

  return (
    <Dialog
      open={dashboardIsOpen}
      onClose={closeDashboard}
      TransitionComponent={Transition}
      maxWidth="lg"
      style={{ padding: 5 }}
    >
      <UserTable
        openAddForm={props.openAddUserForm}
        setCurrentUser={props.setCurrentUser}
        editRow={props.editUser}
        deleteUser={props.deleteUser}
        rows={props.rows}
      />
      <CreateUserForm
        addRow={props.createUser}
        adding={props.addingUser}
        closeForm={props.closeAddUserForm}
        closeDashBoard={props.closeDashboard}
      />
      <EditUserForm
        updateRow={props.updateUser}
        currentRow={props.currentUser}
        editing={props.editingUser}
        editRow={props.editingUser}
        closeForm={props.closeEditUserForm}
        closeDashBoard={props.closeDashboard}
      />
    </Dialog>
  );
};

export default UserDashboard;
