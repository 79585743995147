import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDialog(props) {
  const {
    id,
    open,
    title,
    body,
    confirmText,
    handleConfirm,
    cancelText,
    handleClose,
  } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={() => {
              handleConfirm(id);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
