/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import StringConstants from '../resources/StringConstants';

export default function DateFilterBox(props) {
  const {
    fromDate, toDate, setFromDate, setToDate, searchByDate,
  } = props;

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        searchByDate();
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label={props.labelFromDate}
                value={fromDate || ''}
                type="date"
                margin="dense"
                onChange={handleFromDateChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                placeholder="dd-mm-yyyy"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={props.labelToDate}
                value={toDate || ''}
                type="date"
                margin="dense"
                onChange={handleToDateChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                placeholder="dd-mm-yyyy"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={StringConstants.SEARCH}>
            <IconButton type="submit" color="primary">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
