import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StringConstants from '../resources/StringConstants';
import { currencyFormatter } from '../Utils';

export default function PaymentDialog(props) {
  const {
    open,
    title,
    confirmText,
    handleConfirm,
    cancelText,
    handleClose,
  } = props;

  const handlePaidAmountChange = (event) => {
    props.setPaidAmount(event.target.value);
  };

  const handlePaymentTypeChange = (event) => {
    props.setPaymentType(event.target.value);
  };

  const handlePaymentDateChange = (event) => {
    props.setPaymentDate(event.target.value);
  };

  const paymentTypeCategory = [
    {
      name: 'TF',
      description: StringConstants.TRANSFER,
    },
    {
      name: 'CC',
      description: StringConstants.CREDIT_CARD,
    },
    {
      name: 'CA',
      description: StringConstants.CASH,
    },
  ];

  const paymentBankCategory = [
    { name: 'กทN' },
    { name: 'กทT' },
    { name: 'กสT' },
    { name: 'กสN' },
    { name: 'กศT' },
    { name: 'กศN' },
    { name: 'ทพนT' },
    { name: 'ทพนN' },
    { name: 'KTT' },
    { name: 'KTN' },
    { name: 'TMBN' },
  ];

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`ต้องจ่ายทั้งหมด ${currencyFormatter.format(
            props.installment.debt_amount - props.installment.paid_amount,
          )}`}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  label={StringConstants.AMOUNT_TO_PAY}
                  id="amount_to_pay"
                  onChange={handlePaidAmountChange}
                  defaultValue={props.installment.debt_amount - props.installment.paid_amount}
                  value={props.amountToPay}
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs>
                <Button
                  onClick={() => props.setPaidAmount(
                    parseFloat(props.installment.debt_amount - props.installment.paid_amount),
                  )}
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 8 }}
                >
                  {StringConstants.PAY_ALL}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={StringConstants.PAYMENT_TYPE}
              id="payment_type"
              onChange={handlePaymentTypeChange}
              value={props.paymentType}
              fullWidth
              select
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
            >
              {paymentTypeCategory.map((i) => (
                <MenuItem value={i.name}>{i.description}</MenuItem>
              ))}
            </TextField>
            {
              props.paymentType === 'TF' || props.paymentType === 'CC'
                ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      autoHighlight
                      handleHomeEndKeys
                      value={props.paymentBank}
                      onChange={(event, newValue) => {
                        props.setPaymentBank(newValue);
                      }}
                      inputValue={props.paymentInputBank}
                      onInputChange={(event, newInputValue) => {
                        props.setPaymentInputBank(newInputValue);
                      }}
                      id="payment_bank"
                      options={paymentBankCategory.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={StringConstants.PAYMENT_BANK}
                          margin="dense"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                          fullWidth
                          type="search"
                        />
                      )}
                    />
                  </Grid>
                ) : ''
            }
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label={StringConstants.PAYMENT_DATE}
                  id="payment_date"
                  onChange={handlePaymentDateChange}
                  value={props.paymentDate}
                  type="date"
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            if (props.amountToPay > (parseFloat(props.installment.debt_amount) - parseFloat(props.installment.paid_amount))) {
              alert(StringConstants.AMOUNT_TO_PAY_IS_MORE_THAN_DEBT);
              return;
            }

            if (props.amountToPay <= 0) {
              alert(StringConstants.AMOUNT_TO_PAY_IS_ZERO_OR_NEGATIVE);
              return;
            }

            if ((props.paymentType === 'TF' || props.paymentType === 'CC') && !props.paymentBank) {
              alert(StringConstants.PLEASE_FILL_ALL_REQUIREMENT);
              return;
            }

            if (props.paymentType && props.paymentDate && props.amountToPay) handleConfirm();
            else alert(StringConstants.PLEASE_FILL_ALL_REQUIREMENT);
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
