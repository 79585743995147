import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import StringConstants from '../resources/StringConstants';
import { Transition } from './DialogConfig';
import FormInputFields from './FormInputFields';
import FormInstallmentFields from './FormInstallmentFields';
import InstallmentPreview from './InstallmentPreview';
import { currencyFormatter } from '../Utils';

const AddDebtorForm = (props) => {
  const initialFormState = {
    id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    number_plate: '',
    registered_date: '',
    start_pay_date: '',
    company: '',
    insurance_type: '',
    note: '',
  };

  const [debtor, setDebtor] = useState(initialFormState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDebtor({ ...debtor, [name]: value });
  };

  const handleStartPayDateChange = (event) => {
    const { value } = event.target;
    setDebtor({ ...debtor, start_pay_date: value });
    props.setStartPayingDate(value);
    console.log(value);
  };

  const {
    addDebtor,
    closeAddForm,
    totalDebtAmount,
    downPaymentAmount,
    monthlySpread,
    calculatePayment,
    startPayingDate,
    adding,
  } = props;

  return (
    <Dialog
      open={adding}
      onClose={closeAddForm}
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (
            !/\d/.test(debtor.phone_number)
            && debtor.phone_number.length > 10
          ) alert(StringConstants.PHONE_NUMBER_SHOULD_BE_NUMBER);
          else if (
            parseFloat(props.totalDebtAmount)
              - (parseFloat(props.downPaymentAmount)
                + parseFloat(props.downPaymentAmount2))
            <= 0
          ) {
            alert(StringConstants.TOTAL_DEBT_SHOULD_BE_MORE_THAN_DOWN_PAYMENT);
          } else {
            addDebtor(debtor);
            closeAddForm();
            setDebtor(initialFormState);
          }
        }}
      >
        <DialogTitle id="form-dialog-title">
          {StringConstants.ADD_DEBTOR_FORM_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {StringConstants.PLEASE_FILL_ALL_REQUIREMENT}
          </DialogContentText>
          <FormInputFields
            onChange={handleInputChange}
            debtor={debtor}
            setDueDate={props.setDueDate}
            setDebtor={setDebtor}
            isAdd
          />
          <br />
          <FormInstallmentFields
            handleStartPayDateChange={handleStartPayDateChange}
            setTotalDebtAmount={props.setTotalDebtAmount}
            setDownPaymentAmount={props.setDownPaymentAmount}
            setDownPaymentAmount2={props.setDownPaymentAmount2}
            setMonthlySpread={props.setMonthlySpread}
          />
          {totalDebtAmount
          && downPaymentAmount
          && monthlySpread
          && startPayingDate ? (
            <InstallmentPreview
              debtor={debtor}
              totalDebtAmount={totalDebtAmount}
              downPaymentAmount={props.downPaymentAmount}
              downPaymentAmount2={props.downPaymentAmount2}
              calculatePayment={calculatePayment}
              monthlySpread={monthlySpread}
              startPayingDate={startPayingDate}
              currencyFormatter={currencyFormatter}
            />
            ) : (
              ''
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddForm} color="secondary">
            {StringConstants.CANCEL}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {StringConstants.CONFIRM}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDebtorForm;
