import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Cookies from 'js-cookie';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import TableChartIcon from '@material-ui/icons/TableChart';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import StringConstants from '../resources/StringConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

// const StyledBadge = withStyles((theme) => ({
//   badge: {
//     right: 9,
//     top: 15,
//     padding: '0 4px',
//   },
// }))(Badge);

const TopBar = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    props.setMainUser('');
    Cookies.remove('username');
    Cookies.remove('password');
    Cookies.remove('is_super');
    props.setAuth(false);
    // Refresh when logout to clear forms and components states
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography variant="h6" className={classes.title}>
            {StringConstants.APP_TITLE}
          </Typography>
          {props.auth && (
            <div>
              {props.mainUser.username}
              <Tooltip title={StringConstants.USER}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {props.mainUser.is_super ? (
                  <MenuItem onClick={props.openDashboard}>
                    {StringConstants.USER_DASHBOARD_ADD}
                  </MenuItem>
                ) : (
                  ''
                )}
                {props.mainUser.is_super ? (
                  <MenuItem
                    onClick={() => {
                      window.open(
                        'https://api.wawiinsure.info/admin/api/user/',
                        '_blank',
                      );
                    }}
                  >
                    {StringConstants.USER_DASHBOARD}
                  </MenuItem>
                ) : (
                  ''
                )}
                <MenuItem onClick={handleLogout}>
                  {StringConstants.LOGOUT}
                </MenuItem>
              </Menu>
              {/* <StyledBadge
                color="secondary"
                badgeContent={props.notificationCount}
              >
                {
                  !props.actionCenterOpen ?
                    <Tooltip title={StringConstants.SHOW_OVERDUES}>
                      <IconButton onClick={toggleActionCenter} color="inherit">
                        <ViewAgendaIcon />
                      </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={StringConstants.SHOW_TABLE}>
                      <IconButton onClick={toggleActionCenter} color="inherit">
                        <TableChartIcon />
                      </IconButton>
                    </Tooltip>
                }

              </StyledBadge> */}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
