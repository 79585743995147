import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import PaymentIcon from '@material-ui/icons/Payment';
import Tooltip from '@material-ui/core/Tooltip';
import StringConstants from '../resources/StringConstants';
import AppSettings from '../AppSettings';
import PaymentDialog from '../components/PaymentDialog';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { currencyFormatter, formatThaiDate } from '../Utils';
import AnnotatedTableCell from '../components/AnnotatedTableCell';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function getPaymentType(category) {
  switch (category) {
    case 'TF':
      return StringConstants.TRANSFER;
    case 'CC':
      return StringConstants.CREDIT_CARD;
    case 'CA':
      return StringConstants.CASH;
    default: return 'ข้อมูลผิดพลาด';
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.INSTALLMENT_NUMBER,
  },
  {
    id: 'due_date',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.DUE_DATE,
  },
  {
    id: 'debt_amount',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.DEBT_AMOUNT,
  },
  {
    id: 'payment_date',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.PAYMENT_DATE,
  },
  {
    id: 'paid_amount',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.PAID_AMOUNT,
  },
  {
    id: 'payment_type',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.PAYMENT_TYPE,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: StringConstants.PAY,
  },
];

export const InstallmentTable = (props) => {
  const { rows } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(AppSettings.installmentTableSettings.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    AppSettings.installmentTableSettings.rowPerPage,
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [isPaying, setIsPaying] = useState(false);
  const [currentInstallment, setCurrentInstallment] = useState({});
  const openPaymentDialog = (row) => {
    setIsPaying(true);
    setCurrentInstallment(row);
  };

  const closePaymentDialog = () => {
    setIsPaying(false);
  };

  const [amountToPay, setPaidAmount] = useState(0);
  const [paymentType, setPaymentType] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [paymentBank, setPaymentBank] = useState('');
  const [paymentInputBank, setPaymentInputBank] = useState('');

  const handlePayment = () => {
    const installment = {
      id: currentInstallment.id,
      debtor: currentInstallment.debtor,
      debt_amount: currentInstallment.debt_amount,
      paid_amount:
        parseFloat(currentInstallment.paid_amount) + parseFloat(amountToPay),
      payment_type: paymentType,
      due_date: currentInstallment.due_date,
      payment_date: paymentDate,
      is_paid:
        parseFloat(currentInstallment.debt_amount)
          - parseFloat(currentInstallment.paid_amount)
        === parseFloat(amountToPay),
      is_down_payment: currentInstallment.is_down_payment,
      payment_bank: paymentInputBank || paymentBank,
    };
    props
      .updateInstallment(currentInstallment.id, installment)
      .then(() => {
        props.handleOpenNotify(
          `${StringConstants.NOTIFY_PAID}: ${currencyFormatter.format(
            amountToPay,
          )} ${StringConstants.CURRENCY}`,
        );
      })
      .catch((e) => {
        props.handleOpenNotify(
          `${StringConstants.ERROR_PAYMENT}\n ERROR: ${e}`,
          'error',
        );
      });
    setIsPaying(false);
  };

  function formatId(row) {
    if (row.is_down_payment) {
      const id_number = row.id.split('D')[1];
      if (id_number === '1') return StringConstants.DOWN_PAYMENT;
      return `${StringConstants.DOWN_PAYMENT} ${id_number}`;
    }
    return row.id.split('I')[1];
  }

  return (
    <div className={classes.root}>
      <PaymentDialog
        installment={currentInstallment}
        open={isPaying}
        title={StringConstants.PAY_CONFIRMATION}
        confirmText={StringConstants.CONFIRM}
        handleConfirm={handlePayment}
        handleClose={closePaymentDialog}
        cancelText={StringConstants.CANCEL}
        amountToPay={amountToPay}
        paymentType={paymentType}
        paymentDate={paymentDate}
        paymentBank={paymentBank}
        paymentInputBank={paymentInputBank}
        setPaidAmount={setPaidAmount}
        setPaymentType={setPaymentType}
        setPaymentDate={setPaymentDate}
        setPaymentBank={setPaymentBank}
        setPaymentInputBank={setPaymentInputBank}
      />
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAddForm={props.openAddForm}
          rows={rows}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {props.rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isItemSelected = isSelected(row.id);

                    return (
                      <>
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <AnnotatedTableCell>
                            {formatId(row)}
                          </AnnotatedTableCell>
                          <AnnotatedTableCell>
                            {formatThaiDate(row.due_date)}
                            {new Date(row.due_date) < new Date() && !row.is_paid
                              ? ' (ค้างเกิน)'
                              : null}
                          </AnnotatedTableCell>
                          <AnnotatedTableCell align="right">
                            {currencyFormatter.format(row.debt_amount)}
                          </AnnotatedTableCell>
                          <AnnotatedTableCell>
                            {row.payment_date
                              ? formatThaiDate(row.payment_date)
                              : StringConstants.UNPAID}
                          </AnnotatedTableCell>
                          <AnnotatedTableCell align="right">
                            {currencyFormatter.format(row.paid_amount)}
                          </AnnotatedTableCell>
                          <AnnotatedTableCell>
                            {row.payment_type
                              ? row.payment_bank
                                ? `${getPaymentType(row.payment_type)
                                }: ${
                                  row.payment_bank}`
                                : getPaymentType(row.payment_type)
                              : StringConstants.UNPAID}
                          </AnnotatedTableCell>
                          <TableCell>
                            {row.is_paid ? (
                              ` (${StringConstants.PAID})`
                            ) : (
                              <Tooltip title={StringConstants.PAY}>
                                <IconButton
                                  onClick={() => openPaymentDialog(row)}
                                >
                                  <PaymentIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={13}>
                    {StringConstants.DATA_NOT_FOUND}
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={13} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={StringConstants.ROWS_PER_PAGE}
          nextIconButtonText={StringConstants.NEXT_PAGE}
          backIconButtonText={StringConstants.PREVIOUS_PAGE}
        />
      </Paper>
    </div>
  );
};

export default InstallmentTable;
