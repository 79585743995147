import StringConstants from './resources/StringConstants';

export const currencyFormatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
});

export function formatPhoneNumber(phoneNumberString) {
  /*
  Support two format
  1. (08X) XXX XXXX <- require 10 chars
  2. (02) XXX XXXX <- require 9 chars
  */
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  let match;
  if (cleaned.length === 10) match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  else if (cleaned.length === 9) match = cleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
  else {
    return StringConstants.WRONG_PHONE_NUMBER_FORMAT;
  }
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
}

export function formatThaiDate(dateStr) {
  const [year, month, day] = dateStr.split('-');
  return `${day}/${month}/${parseInt(year, 10) + 543}`;
}

// Python-like range() function
export function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

export function dateToString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function GetSortOrder(prop) {
  /* Sort array based on [prop] */
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return 1;
    }
    if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function GroupByInitial(rawData) {
  /*
    since data at this point is an object, to get array of values
    we use Object.values method

    let result = Object.values(GroupByInitial([rawData]))
    console.log(result)
   */
  return rawData.reduce((r, e) => {
    // get first letter of name of current element
    const group = e.name[0];
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] };
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    // return accumulator
    return r;
  }, {});
}
