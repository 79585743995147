import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from 'react-csv';
import IconButton from '@material-ui/core/IconButton';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StringConstants from '../resources/StringConstants';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

function prepareJSON(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    delete array[i].installments;
    let line = '';
    // noinspection JSUnfilteredForInLoop
    for (const index in array[i]) {
      if (line !== '') line += ',';

      line += array[i][index];
    }

    str += `${line}\r\n`;
  }

  return str;
}

function formatDate() {
  const current_datetime = new Date();
  return `${current_datetime.getFullYear()}-${
    current_datetime.getMonth() + 1
  }-${current_datetime.getDate()}`;
}

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  const { rows, handlePrint, openAddForm, isDebtor } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (isDebtor) {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {StringConstants.SELECT} {numSelected} {StringConstants.ROW}
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {StringConstants.DEBTOR_TABLE_TITLE}
          </Typography>
        )}

        {/* {rows.length > 0 ? ( */}
        {/*  <Tooltip title={StringConstants.EXPORT}> */}
        {/*    <CSVLink */}
        {/*      data={prepareJSON(JSON.stringify(rows))} */}
        {/*      filename={`${formatDate()}_ข้อมูลลูกหนี้`} */}
        {/*    > */}
        {/*      <IconButton aria-label="export"> */}
        {/*        <ImportExportIcon /> */}
        {/*      </IconButton> */}
        {/*    </CSVLink> */}
        {/*  </Tooltip> */}
        {/* ) : ( */}
        {/*  '' */}
        {/* )} */}

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handlePrint('A');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_A}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrint('B');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_B}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrint('C');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_C}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrint('D');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_D}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrint('E');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_E}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handlePrint('F');
              handleCloseMenu();
            }}
          >
            {StringConstants.PRINT_TABLE_F}
          </MenuItem>
        </Menu>

        <Tooltip title={StringConstants.PRINT}>
          <IconButton aria-label="print" onClick={handleMenu}>
            <PrintIcon />
          </IconButton>
        </Tooltip>

        {numSelected > 0 ? (
          <Tooltip title={StringConstants.DELETE}>
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={StringConstants.ADD}>
            <IconButton color="primary" onClick={openAddForm} aria-label="add">
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  if (props.isUser) {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {StringConstants.USER_TABLE_TITLE}
        </Typography>

        <Tooltip title={StringConstants.ADD}>
          <IconButton onClick={openAddForm} aria-label="add" color="primary">
            <PersonAddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  }
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {StringConstants.INSTALLMENT_TABLE_TITLE}
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  isDebtor: PropTypes.bool,
};
