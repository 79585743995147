import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import Fade from '@material-ui/core/Fade';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} timeout={200} />
));
