import React from 'react';
import { Tooltip, TableCell } from '@material-ui/core';

export const AnnotatedTableCell = (props) => {
  const text = props.children;

  return (
    <TableCell>
      <Tooltip title={text} arrow interactive>
        <span>{text}</span>
      </Tooltip>
    </TableCell>
  );
};

export default AnnotatedTableCell;
