import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DebtorTable from './tables/DebtorTable';
import AddDebtorForm from './forms/AddDebtorForm';
import EditDebtorForm from './forms/EditDebtorForm';
import TopBar from './components/TopBar';
import InfoBox from './components/InfoBox';
import StringConstants from './resources/StringConstants';
import SearchBox from './components/SearchBox';
import DateFilterBox from './components/DateFilterBox';
import IDFilterBox from './components/IDFilterBox';
import Login from './components/Login';
// import { OverDueInfoBox } from './components/OverDueInfoBox';
import { currencyFormatter } from './Utils';
import UserDashboard from './components/UserDashboard';
import Notification from './components/Notification';
import DateChooserDialog from './components/DateChooserDialog';
import { BACKEND_URL } from './AppSettings';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Context
  const GlobalDataContext = React.createContext({ currentFilters: ['id'] });
  const globalData = React.useContext(GlobalDataContext)

  /*
    Notification
  */
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState('');
  const [notifySeverity, setNotifySeverity] = useState('success');

  const handleOpenNotify = (message, severity = 'success') => {
    setNotifySeverity(severity);
    setNotifyMessage(message);
    setNotifyOpen(true);
  };
  const handleCloseNotify = () => {
    setNotifyOpen(false);
  };

  /* ----------------DEBTOR--------------------*/
  const [debtors, setDebtors] = useState(false);
  const [constDebtors, setConstDebtors] = useState(false);
  const [currentDebtor, setCurrentDebtor] = useState({
    id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    number_plate: '',
    registered_date: '',
    expire_date: '',
    start_pay_date: '',
    company: '',
    total_debt_amount: '',
    down_payment: '',
    note: '',
    installments: [],
  });
  const [addingDebtor, setAddingDebtor] = useState(false);
  const [editingDebtor, setEditingDebtor] = useState(false);

  const fetchData = useCallback(async (setData) => {
    const response = await fetch(`${BACKEND_URL}/debtor_installment/`);
    response
      .json()
      .then((res) => setData(res))
      .catch((err) => console.error(err));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData(setDebtors).then(() => fetchData(setConstDebtors));
  }, [fetchData]);

  async function createDebtor(debtor) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(debtor),
    };
    const response = await fetch(`${BACKEND_URL}/debtor/`, requestOptions);
    response
      .json()
      .then((res) => pushInstallmentDataToServer(res))
      .catch((err) => console.error(err));
    handleOpenNotify(
      `${StringConstants.NOTIFY_ADDED_NEW_DEBTOR}: ${debtor.first_name} ${debtor.last_name}`,
    );
  }

  async function updateDebtor(id, debtor) {
    setEditingDebtor(false);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(debtor),
    };
    const res = await fetch(`${BACKEND_URL}/debtor/${id}/`, requestOptions);
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchData(setDebtors);
    handleOpenNotify(
      `${StringConstants.NOTIFY_UPDATED_DEBTOR}: ${debtor.first_name} ${debtor.last_name}`,
    );
  }

  async function deleteDebtor(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await fetch(`${BACKEND_URL}/debtor/${id}/`, requestOptions);
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchData(setDebtors);
    await fetchData(setConstDebtors);
    handleOpenNotify(`${StringConstants.NOTIFY_DELETE_DEBTOR}`);
  }

  const editDebtor = (debtor) => {
    setEditingDebtor(true);
    setCurrentDebtor({
      id: debtor.id,
      first_name: debtor.first_name,
      last_name: debtor.last_name,
      phone_number: debtor.phone_number,
      number_plate: debtor.number_plate,
      registered_date: debtor.registered_date,
      expire_date: debtor.expire_date,
      start_pay_date: debtor.start_pay_date,
      company: debtor.company,
      total_debt_amount: debtor.total_debt_amount,
      down_payment: debtor.down_payment,
      note: debtor.note,
      insurance_type: debtor.insurance_type,
      line_id: debtor.line_id,
      installments: debtor.installments,
    });
  };

  const openAddForm = () => {
    setAddingDebtor(true);
  };

  const closeAddForm = () => {
    setAddingDebtor(false);
    clearInstallmentFields();
  };

  const closeEditForm = () => {
    setEditingDebtor(false);
  };

  const handlePrint = (type) => {
    sessionStorage.setItem('debtors', JSON.stringify(debtors));
    sessionStorage.setItem('constDebtors', JSON.stringify(constDebtors));
    sessionStorage.setItem(
      'overDueDebtors',
      JSON.stringify(generateOverDueDebtors()),
    );

    if (type === 'A') window.open(`${process.env.PUBLIC_URL}tableA.html`);
    else if (type === 'B') window.open(`${process.env.PUBLIC_URL}tableBA.html`);
    else if (type === 'C') window.open(`${process.env.PUBLIC_URL}tableC.html`);
    else if (type === 'D') window.open(`${process.env.PUBLIC_URL}tableD.html`);
    else if (type === 'E') {
      setOpenE(true)
    }
    else if (type === 'F') {
      setOpenF(true)
    }
  };

  /* ----------------USER--------------------*/
  const [users, setUsers] = useState(false);
  const [mainUser, setMainUser] = useState();
  const [currentUser, setCurrentUser] = useState({
    username: '',
    password: '',
    is_super: '',
  });
  const [editingUser, setEditingUser] = useState(false);

  const fetchUser = useCallback(async () => {
    const res = await fetch(`${BACKEND_URL}/user/`);
    res
      .json()
      .then((res) => setUsers(res))
      .catch((err) => console.error(err));
  }, []);

  async function createUser(user) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    };
    const res = await fetch(`${BACKEND_URL}/user/`, requestOptions);
    res
      .json()
      .then((res) => setUsers(res))
      .catch((err) => console.error(err));
    await fetchUser();
    handleOpenNotify(
      `${StringConstants.NOTIFY_ADDED_NEW_USER}: ${user.username}`,
    );
  }

  async function updateUser(id, user) {
    setEditingDebtor(false);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    };
    const res = await fetch(`${BACKEND_URL}/user/${id}/`, requestOptions);
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchUser();
    handleOpenNotify(
      `${StringConstants.NOTIFY_UPDATED_USER}: ${user.username}`,
    );
  }

  async function deleteUser(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await fetch(`${BACKEND_URL}/user/${id}/`, requestOptions);
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchUser();
    handleOpenNotify(`${StringConstants.NOTIFY_DELETE_USER}`);
  }

  const editUser = (user) => {
    setEditingUser(true);
    setCurrentUser({
      username: user.username,
      password: user.password,
      is_super: user.is_super,
    });
  };

  const [addingUser, setAddingUser] = useState(false);

  const [dashboardIsOpen, setDashboardIsOpen] = useState(false);

  const openAddUserForm = () => {
    setAddingUser(true);
  };

  const closeAddUserForm = () => {
    setAddingUser(false);
  };

  const closeEditUserForm = () => {
    setEditingUser(false);
  };

  const openDashboard = () => {
    setDashboardIsOpen(true);
  };

  const closeDashboard = () => {
    setDashboardIsOpen(false);
  };

  /*
  ----------------INSTALLMENT-------------------
  */
  const [totalDebtAmount, setTotalDebtAmount] = useState(0);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [downPaymentAmount2, setDownPaymentAmount2] = useState(0);
  const [monthlySpread, setMonthlySpread] = useState(0);
  const [startPayingDate, setStartPayingDate] = useState();

  const calculateMonthlyPayment = () => {
    if (totalDebtAmount && downPaymentAmount) {
      return (
        Math.floor(
          parseFloat(totalDebtAmount)
          - (parseFloat(downPaymentAmount) + parseFloat(downPaymentAmount2)),
        ) / monthlySpread
      );
    }
    return 0;
  };

  const calculateTotalDebtAmount = (installments) => {
    let totalDebtAmount = 0.0;
    installments.forEach((installment) => {
      totalDebtAmount += parseFloat(installment.debt_amount);
    });
    return totalDebtAmount;
  };

  const calculateTotalPaidAmount = (installments) => {
    let totalPaidAmount = 0.0;
    for (const installment of installments) {
      totalPaidAmount += parseFloat(installment.paid_amount);
    }
    return totalPaidAmount;
  };

  function addMonths(months, date) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  function addDays(days, date) {
    const someDate = date;
    const numberOfDaysToAdd = days;
    someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    return someDate;
  }

  function dateToShortenString(date) {
    const newDate = date.toISOString();
    return newDate.substring(0, 10);
  }

  const pushInstallmentDataToServer = (debtor) => {
    const monthlyInstallment = calculateMonthlyPayment();
    console.log(monthlyInstallment);
    const installmentLeftOver = (parseFloat(totalDebtAmount)
      - (parseFloat(downPaymentAmount) + parseFloat(downPaymentAmount2)))
      % monthlySpread;
    const installments = [];
    const downPayments = [];
    // 1st downPayment
    downPayments.push({
      id: `${debtor.id}D${1}`,
      debtor: debtor.id,
      debt_amount: downPaymentAmount,
      due_date: startPayingDate,
      is_down_payment: true,
      is_paid: false,
      paid_amount: 0,
      payment_type: null,
      note: '',
    });
    // 2nd downPayment, if any
    if (downPaymentAmount2) {
      downPayments.push({
        id: `${debtor.id}D${2}`,
        debtor: debtor.id,
        debt_amount: downPaymentAmount2,
        due_date: dateToShortenString(addDays(7, new Date(startPayingDate))),
        is_down_payment: true,
        is_paid: false,
        paid_amount: 0,
        payment_type: null,
        note: '',
      });
    }

    downPayments.map(
      async (downPayment) => await createInstallment(downPayment),
    );

    for (let i = 0; i < monthlySpread; i++) {
      let debtAmount;
      if (i === 0) debtAmount = monthlyInstallment + installmentLeftOver;
      else debtAmount = monthlyInstallment;
      installments.push({
        id: `${debtor.id}I${i + 1}`,
        debtor: debtor.id,
        debt_amount: debtAmount,
        due_date: dateToShortenString(
          addMonths(i + 1, new Date(startPayingDate)),
        ),
        is_down_payment: false,
        is_paid: false,
        paid_amount: 0,
        payment_type: null,
        note: '',
      });
    }

    installments.map(
      async (installment) => await createInstallment(installment),
    );
  };

  async function createInstallment(installment) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(installment),
    };
    const res = await fetch(`${BACKEND_URL}/installment/`, requestOptions);
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchData(setDebtors);
    await fetchData(setConstDebtors);
  }

  async function updateInstallment(id, installment) {
    setEditingDebtor(false);
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(installment),
    };
    const res = await fetch(
      `${BACKEND_URL}/installment/${id}/`,
      requestOptions,
    );
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchData(setDebtors);
    await fetchData(setConstDebtors);
    return res;
  }

  async function deleteInstallment(id) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };
    const res = await fetch(
      `${BACKEND_URL}/installment/${id}/`,
      requestOptions,
    );
    res
      .json()
      .then()
      .catch((err) => console.error(err));
    await fetchData(setDebtors);
    await fetchData(setConstDebtors);
  }

  const [value, setValue] = React.useState('');
  const [fromID, setFromID] = useState('');
  const [toID, setToID] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fromExpireDate, setFromExpireDate] = useState('');
  const [toExpireDate, setToExpireDate] = useState('');
  const [fromInsDueDate, setFromInsDueDate] = useState('');
  const [toInsDueDate, setToInsDueDate] = useState('');

  const search = () => {
    // Set this for other purpose other than filtering in the table such as handlePrint()
    // As value & inputValue are cleared at this end of this function
    if (value) {
      if (value.startsWith('A')) {
        setDebtors(
          constDebtors.filter(
            (debtor) => debtor.id == value.substr(1),
          ),
        );
      } else if (/^\s*$/.test(value) || value == '') {
        setDebtors(constDebtors)
      }
      else {
        setDebtors(
          constDebtors.filter(
            (debtor) => debtor.first_name.includes(value)
              || debtor.last_name.includes(value)
              || `${debtor.first_name} ${debtor.last_name}`.includes(value),
          ),
        );
      }
    }
    setValue('');
  };

  const clearSearch = () => {
    setDebtors(constDebtors);
    setToDate('');
    setFromDate('');
    setToExpireDate('');
    setFromExpireDate('');
    setToInsDueDate('');
    setFromInsDueDate('');
  };

  const searchByDate = () => {
    // Set this for other purpose other than filtering in the table such as handlePrint()

    if (new Date(fromDate) > new Date(toDate)) {
      alert(StringConstants.FROM_DATE_IS_LESS);
      return;
    }

    if (fromDate === '' && toDate === '') return;

    let filteredDebtor;

    if (fromDate && toDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.registered_date) >= new Date(fromDate)
          && new Date(debtor.registered_date) <= new Date(toDate),
      );
    } else if (fromDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.registered_date) >= new Date(fromDate),
      );
    } else if (toDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.registered_date) <= new Date(toDate),
      );
    }

    setDebtors(filteredDebtor);
  };

  const searchByID = () => {
    // Set this for other purpose other than filtering in the table such as handlePrint()

    if (fromID === '' && toID === '') return;

    const FROM_ID = fromID.toUpperCase().replace('A', '')
    const TO_ID = toID.toUpperCase().replace('A', '')

    let filteredDebtor;

    if (FROM_ID && TO_ID) {
      filteredDebtor = constDebtors.filter(
        (debtor) => (debtor.id) >= (FROM_ID)
          && (debtor.id) <= (TO_ID),
      );
    } else if (FROM_ID) {
      filteredDebtor = constDebtors.filter(
        (debtor) => (debtor.id) >= (FROM_ID),
      );
    } else if (TO_ID) {
      filteredDebtor = constDebtors.filter(
        (debtor) => (debtor.id) <= (TO_ID),
      );
    }

    setDebtors(filteredDebtor);
  };

  const searchByExpireDate = () => {
    // Set this for other purpose other than filtering in the table such as handlePrint()

    if (new Date(fromExpireDate) > new Date(toExpireDate)) {
      alert(StringConstants.FROM_DATE_IS_LESS);
      return;
    }

    if (fromExpireDate === '' && toExpireDate === '') return;

    let filteredDebtor;

    if (fromExpireDate && toExpireDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.expire_date) >= new Date(fromExpireDate)
          && new Date(debtor.expire_date) <= new Date(toExpireDate),
      );
    } else if (fromExpireDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.expire_date) >= new Date(fromExpireDate),
      );
    } else if (toExpireDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(debtor.expire_date) <= new Date(toExpireDate),
      );
    }

    setDebtors(filteredDebtor);
  };

  const searchByInsDueDate = () => {
    if (new Date(fromInsDueDate) > new Date(toInsDueDate)) {
      alert(StringConstants.FROM_DATE_IS_LESS);
      return;
    }

    if (fromInsDueDate === '' && toInsDueDate === '') return;

    let filteredDebtor;

    if (fromInsDueDate && toInsDueDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(
          debtor.installments[debtor.installments.length - 1].due_date,
        ) >= new Date(fromInsDueDate)
          && new Date(
            debtor.installments[debtor.installments.length - 1].due_date,
          ) <= new Date(toInsDueDate),
      );
    } else if (fromInsDueDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(
          debtor.installments[debtor.installments.length - 1].due_date,
        ) >= new Date(fromInsDueDate),
      );
    } else if (toInsDueDate) {
      filteredDebtor = constDebtors.filter(
        (debtor) => new Date(
          debtor.installments[debtor.installments.length - 1].due_date,
        ) <= new Date(toInsDueDate),
      );
    }

    setDebtors(filteredDebtor);
  };

  function getAllUnpaid() {
    if (!debtors.length) return false;
    return debtors.filter(
      (debtor) => calculateTotalDebtAmount(debtor.installments)
        !== calculateTotalPaidAmount(debtor.installments),
    );
  }

  function getExpiringDebtors() {
    if (!debtors.length) return false;
    return debtors.filter((debtor) => {
      const expire_date = new Date(debtor.expire_date);
      const expire_month = expire_date.getMonth();
      const expire_year = expire_date.getFullYear();
      const now_date = new Date();
      const now_month = now_date.getMonth();
      const now_year = now_date.getFullYear();

      return expire_month === now_month && expire_year === now_year;
    });
  }

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  function getDebtorsWithNoMoreInstallment() {
    if (!debtors.length) return false;
    return debtors.filter((debtor) => {
      const { installments } = debtor;
      if (installments.length <= 0) return false
      installments.sort(GetSortOrder('id'));
      // Comment for debugging
      // console.log('Debtor:', debtor)
      // console.log('Ins:', installments[installments.length - 1].due_date)
      const expire_date = new Date(
        installments[installments.length - 1].due_date
      );
      const expire_month = expire_date.getMonth();
      const expire_year = expire_date.getFullYear();
      const now_date = new Date();
      const now_month = now_date.getMonth();
      const now_year = now_date.getFullYear();

      return expire_month === now_month && expire_year === now_year;
    });
  }

  function calculateDebtAmountLeft(row) {
    const result = calculateTotalDebtAmount(row.installments)
      - calculateTotalPaidAmount(row.installments);
    if (result <= 0) return StringConstants.PAID;
    return result;
  }

  function calculateDebtAmountLeftForFLoat(row) {
    const result = calculateTotalDebtAmount(row.installments)
      - calculateTotalPaidAmount(row.installments);
    if (result <= 0) return 0;
    return result;
  }

  function getDebtLeftOfAll() {
    return getAllUnpaid().reduce(
      (sum, debtor) => sum + parseFloat(calculateDebtAmountLeftForFLoat(debtor)),
      0,
    );
  }

  function getAllPaid() {
    if (!debtors.length) return false;
    return debtors.filter(
      (debtor) => parseFloat(calculateTotalDebtAmount(debtor.installments))
        === parseFloat(calculateTotalPaidAmount(debtor.installments)),
    );
  }

  const [auth, setAuth] = useState(false);

  function generateOverDueInstallments(debtor) {
    const overDues = [];
    for (const installment of debtor.installments) {
      if (!installment.is_paid && new Date(installment.due_date) < new Date()) {
        overDues.push(installment);
      }
    }
    return overDues;
  }

  const generateOverDueDebtors = () => {
    const overDueDebtors = [];

    for (const debtor of debtors) {
      const overDueInstallments = generateOverDueInstallments(debtor);
      if (overDueInstallments) {
        if (overDueInstallments.length > 0) {
          debtor.overDueInstallments = overDueInstallments;
          overDueDebtors.push(debtor);
        }
      }
    }

    return overDueDebtors;
  };

  const clearInstallmentFields = () => {
    setTotalDebtAmount(undefined);
    setDownPaymentAmount(0);
    setDownPaymentAmount2(0);
    setStartPayingDate(undefined);
    setMonthlySpread(0);
  };

  // ระบบเลือกเดือนและปีสำหรับ TABLE E
  const [OpenE, setOpenE] = useState(false);
  const closeE = () => {
    setOpenE(false)
  }
  const getDebtorsByPaymentDate = (month, year) => {
    const newDebtorList = []
    const debtorForCheck = JSON.parse(JSON.stringify(constDebtors))

    debtorForCheck.forEach((debtor) => {
      let newDebtor = debtor
      let installments = debtor.installments
      installments.sort(GetSortOrder('id'));
      /*       newDebtor.installments = debtor.installments.filter(ins => {
              const payment_date = new Date(ins.payment_date);
              const payment_month = payment_date.getMonth() + 1;
              const payment_year = payment_date.getFullYear();
      
              return payment_month === month && payment_year === year && ins.is_paid;
            }) */
      let newInstallments = []
      let ins = installments[installments.length - 1]
      const payment_date = new Date(ins.payment_date);
      const payment_month = payment_date.getMonth() + 1;
      const payment_year = payment_date.getFullYear();
      if (payment_month === month && payment_year === year && ins.is_paid) {
        newInstallments.push(ins)
      }

      newDebtor.installments = newInstallments


      if (newDebtor.installments.length > 0) {
        newDebtorList.push(newDebtor)
      }
    })

    return newDebtorList
  }
  const setDebtorByPaymentDate = (month, year) => {
    console.log('setDebtorByPaymentDate', month, year)
    const newDebtors = getDebtorsByPaymentDate(month, year)
    console.log('newDebtors', newDebtors)
    sessionStorage.setItem('newDebtors', JSON.stringify(newDebtors));
    window.open(`${process.env.PUBLIC_URL}tableE.html`);
    sessionStorage.removeItem('newDebtors');
  }

  // ระบบเลือกเดือนและปีสำหรับ TABLE F
  const [OpenF, setOpenF] = useState(false);
  const closeF = () => {
    setOpenF(false)
  }
  const getDebtorsByExpireDate = (month, year) => {
    return constDebtors.filter(debtor => {
      const expire_date = new Date(debtor.expire_date);
      const expire_month = expire_date.getMonth() + 1; // return 0 - 11 month, hence + 1
      const expire_year = expire_date.getFullYear();

      return expire_month === month && expire_year === year;
    })
  }
  const setDebtorByExpireDate = (month, year) => {
    console.log('setDebtorByExpireDate', month, year)
    const newDebtors = getDebtorsByExpireDate(month, year)
    console.log('newDebtors', newDebtors)
    sessionStorage.setItem('newDebtors', JSON.stringify(newDebtors));
    window.open(`${process.env.PUBLIC_URL}tableF.html`);
    sessionStorage.removeItem('newDebtors');
  }

  if (isLoading) return (
    <div style={{
      textAlign: 'center',
      verticalAlign: 'middle',
      marginTop: '20%'
    }}>
      <h3 style={{ color: '#1976d2' }}>โปรดรอสักครู่...</h3>
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div >
  )

  return (
    <div>
      <TopBar
        auth={auth}
        setAuth={setAuth}
        mainUser={mainUser}
        setMainUser={setMainUser}
        openDashboard={openDashboard}
      />
      <Notification
        notifyOpen={notifyOpen}
        handleOpenNotify={handleOpenNotify}
        handleCloseNotify={handleCloseNotify}
        notifyMessage={notifyMessage}
        notifySevere={notifySeverity}
      />
      {/* For table E */}
      <DateChooserDialog
        open={OpenE}
        title={StringConstants.DCD_CONFIRMATION}
        body={StringConstants.DCD_CONFIRMATION_BODY}
        confirmText={StringConstants.CONFIRM}
        handleConfirm={setDebtorByPaymentDate}
        cancelText={StringConstants.CANCEL}
        handleClose={closeE}
      />
      {/* For table F */}
      <DateChooserDialog
        open={OpenF}
        title={StringConstants.DCD_CONFIRMATION}
        body={StringConstants.DCD_CONFIRMATION_BODY}
        confirmText={StringConstants.CONFIRM}
        handleConfirm={setDebtorByExpireDate}
        cancelText={StringConstants.CANCEL}
        handleClose={closeF}
      />
      {auth ? (
        <div>
          <Container maxWidth="false" className="content-container">
            <Grid container spacing={2}>
              <Grid item xs={12} lg>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">
                          {StringConstants.SEARCH_AND_FILTER}
                          {constDebtors.length
                            && debtors.length !== constDebtors.length ? (
                            <Tooltip title={StringConstants.CLEAR_SEARCH}>
                              <Button
                                style={{ float: 'right' }}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={clearSearch}
                              >
                                {StringConstants.CLEAR_SEARCH}
                              </Button>
                            </Tooltip>
                          ) : null}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={4}>
                            <SearchBox setValue={setValue} search={search} />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <IDFilterBox
                              fromID={fromID}
                              toID={toID}
                              setFromID={setFromID}
                              setToID={setToID}
                              searchByID={searchByID}
                              labelFromID={StringConstants.SEARCH_BY_ID_FROM}
                              labelToID={StringConstants.SEARCH_BY_ID_TO}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            {/* {globalData.currentFilters} */}
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <DateFilterBox
                              fromDate={fromDate}
                              toDate={toDate}
                              setFromDate={setFromDate}
                              setToDate={setToDate}
                              searchByDate={searchByDate}
                              labelFromDate={StringConstants.FROM_DATE}
                              labelToDate={StringConstants.TO_DATE}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <DateFilterBox
                              fromDate={fromExpireDate}
                              toDate={toExpireDate}
                              setFromDate={setFromExpireDate}
                              setToDate={setToExpireDate}
                              searchByDate={searchByExpireDate}
                              labelFromDate={StringConstants.FROM_EXPIRE_DATE}
                              labelToDate={StringConstants.TO_EXPIRE_DATE}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <DateFilterBox
                              fromDate={fromInsDueDate}
                              toDate={toInsDueDate}
                              setFromDate={setFromInsDueDate}
                              setToDate={setToInsDueDate}
                              searchByDate={searchByInsDueDate}
                              labelFromDate={StringConstants.FROM_INS_DUE_DATE}
                              labelToDate={StringConstants.TO_INS_DUE_DATE}
                            />
                          </Grid>
                          <Grid item xs={12} lg>
                            <Grid container spacing={1}>
                              {debtors.length ? (
                                <InfoBox
                                  color="#fce4ec"
                                  title={StringConstants.TOTAL_UNPAID}
                                  onTitleClick={() => setDebtors(getAllUnpaid)}
                                  contentA={`${getAllUnpaid().length} ${StringConstants.PEOPLE_COUNTER
                                    }`}
                                  contentB={`${currencyFormatter.format(
                                    getDebtLeftOfAll(),
                                  )} ${StringConstants.CURRENCY}`}
                                />
                              ) : null}
                              {debtors.length ? (
                                <InfoBox
                                  color="#e1f5fe"
                                  title={StringConstants.TOTAL_PAID}
                                  onTitleClick={() => setDebtors(getAllPaid)}
                                  contentA={`${getAllPaid().length} ${StringConstants.PEOPLE_COUNTER
                                    }`}
                                />
                              ) : null}
                              {debtors.length ? (
                                <InfoBox
                                  color="#f3e5f5"
                                  title={StringConstants.OVERDUE_DEBTORS}
                                  onTitleClick={() => setDebtors(generateOverDueDebtors())}
                                  contentA={`${generateOverDueDebtors().length
                                    } ${StringConstants.PEOPLE_COUNTER}`}
                                />
                              ) : null}
                              {debtors.length > 0 ? (
                                <InfoBox
                                  color="#fffde7"
                                  title={StringConstants.EXPIRING_DEBTORS}
                                  onTitleClick={() => setDebtors(getExpiringDebtors())}
                                  contentA={`${getExpiringDebtors().length} ${StringConstants.PEOPLE_COUNTER
                                    }`}
                                />
                              ) : null}
                              {debtors.length > 0 ? (
                                <InfoBox
                                  color="#efebe9"
                                  title={StringConstants.NO_MORE_INS_DEBTORS}
                                  onTitleClick={() => setDebtors(
                                    getDebtorsWithNoMoreInstallment(),
                                  )}
                                  contentA={`${getDebtorsWithNoMoreInstallment().length
                                    } ${StringConstants.PEOPLE_COUNTER}`}
                                />
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg>
                    <DebtorTable
                      rows={debtors}
                      editRow={editDebtor}
                      deleteDebtor={deleteDebtor}
                      setCurrentDebtor={setCurrentDebtor}
                      openAddForm={openAddForm}
                      handlePrint={handlePrint}
                      calculateTotalDebtAmount={calculateTotalDebtAmount}
                      calculateTotalPaidAmount={calculateTotalPaidAmount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <UserDashboard
            dashboardIsOpen={dashboardIsOpen}
            openDashboard={openDashboard}
            closeDashboard={closeDashboard}
            openAddUserForm={openAddUserForm}
            closeAddUserForm={closeAddUserForm}
            editUser={editUser}
            editingUser={editingUser}
            closeEditUserForm={closeEditUserForm}
            createUser={createUser}
            addingUser={addingUser}
            deleteUser={deleteUser}
            updateUser={updateUser}
            currentUser={currentUser}
            rows={users}
          />
          <AddDebtorForm
            addDebtor={createDebtor}
            currentDebtor={currentDebtor}
            adding={addingDebtor}
            closeAddForm={closeAddForm}
            setTotalDebtAmount={setTotalDebtAmount}
            setDownPaymentAmount={setDownPaymentAmount}
            setDownPaymentAmount2={setDownPaymentAmount2}
            setMonthlySpread={setMonthlySpread}
            setStartPayingDate={setStartPayingDate}
            totalDebtAmount={totalDebtAmount}
            downPaymentAmount={downPaymentAmount}
            downPaymentAmount2={downPaymentAmount2}
            calculatePayment={calculateMonthlyPayment}
            monthlySpread={monthlySpread}
            startPayingDate={startPayingDate}
          />
          <EditDebtorForm
            updateDebtor={updateDebtor}
            currentDebtor={currentDebtor}
            editing={editingDebtor}
            closeEditForm={closeEditForm}
            updateInstallment={updateInstallment}
            deleteInstallment={deleteInstallment}
            handleOpenNotify={handleOpenNotify}
          />
        </div>
      ) : (
        <Login
          auth={auth}
          setAuth={setAuth}
          setMainUser={setMainUser}
          setUsers={setUsers}
          users={users}
          fetchUser={fetchUser}
        />
      )}
    </div>
  );
};

export default App;
