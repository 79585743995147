import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
} from '@material-ui/core';

import StringConstants from '../resources/StringConstants';

export const InfoBox = (props) => {
  const {
    title, contentA, contentB, onTitleClick, color,
  } = props;

  return (
    <Grid item xs={12} md={4} lg={2} className="info-box">
      <Card
        variant="outlined"
        style={{ backgroundColor: color, height: '100%' }}
      >
        <CardContent>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h5">{contentA}</Typography>
        </CardContent>
        <CardActions>
          <Button variant="outlined" size="small" onClick={onTitleClick}>
            {StringConstants.VIEW_DATA}
          </Button>
          <Typography variant="subtitle">{contentB}</Typography>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default InfoBox;
