import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import StringConstants from '../resources/StringConstants';

const FormInputFields = (props) => {
  const handleInputChange = props.onChange;
  const { debtor } = props;

  function nextYear(date) {
    const res = new Date(date);
    res.setFullYear(res.getFullYear() + 1);
    console.log(res.toDateString());
    return res.toISOString().split('T')[0];
  }

  const onSetYearClick = () => {
    if (debtor.registered_date) {
      props.setDebtor({
        ...debtor,
        expire_date: nextYear(debtor.registered_date),
      });
    } else alert(StringConstants.PLEASE_INPUT_REGISTERED_DATE);
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-start"
      className="form-input-field"
    >
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="first_name"
          label={StringConstants.FIRST_NAME}
          value={debtor.first_name}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          autoFocus
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="last_name"
          label={StringConstants.LAST_NAME}
          value={debtor.last_name}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="company"
          label={StringConstants.COMPANY}
          value={debtor.company}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="number_plate"
          label={StringConstants.NUMBER_PLATE}
          value={debtor.number_plate}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="tel"
          name="phone_number"
          label={StringConstants.PHONE_NUMBER}
          value={debtor.phone_number}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="insurance_type"
          label={StringConstants.INSURANCE_TYPE}
          value={debtor.insurance_type}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="date"
          name="registered_date"
          label={StringConstants.REGISTERED_DATE}
          value={debtor.registered_date}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </Grid>
      <Grid item xs={4} md={1} lg={1}>
        <Button
          style={{ height: '100%' }}
          onClick={onSetYearClick}
          fullWidth
          variant="contained"
          color="primary"
          size="small"
        >
          {StringConstants.PLUS_A_YEAR}
        </Button>
      </Grid>
      <Grid item xs={8} md={11} lg={5}>
        <TextField
          type="date"
          name="expire_date"
          label={StringConstants.EXPIRE_DATE}
          value={debtor.expire_date}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="line_id"
          label={StringConstants.LINE_ID}
          value={debtor.line_id}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          type="text"
          name="note"
          label={StringConstants.NOTE}
          value={debtor.note}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default FormInputFields;
