import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import StringConstants from '../resources/StringConstants';
import ConfirmDialog from '../components/ConfirmDialog';
import AppSettings from '../AppSettings';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { currencyFormatter, formatPhoneNumber, formatThaiDate } from '../Utils';
import AnnotatedTableCell from '../components/AnnotatedTableCell';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.ID,
  },
  {
    id: 'full_name',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.FULL_NAME,
  },
  {
    id: 'company',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.COMPANY,
  },
  {
    id: 'number_plate',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.NUMBER_PLATE,
  },
  {
    id: 'phone_number',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.PHONE_NUMBER,
  },
  {
    id: 'registered_date',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.REGISTERED_DATE,
  },
  {
    id: 'insurance_type',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.INSURANCE_TYPE,
  },
  {
    id: 'down',
    sortable: false,
    align: 'left',
    disablePadding: false,
    label: StringConstants.DOWN,
  },
  {
    id: 'pay_down',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.PAY_DOWN,
  },
  {
    id: 'installments',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.INSTALLMENTS,
  },
  {
    id: 'start_pay_date',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.START_PAY_DATE,
  },
  {
    id: 'last_pay_date',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.LAST_PAY_DATE,
  },
  {
    id: 'expire_date',
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: StringConstants.EXPIRE_DATE,
  },
  // {
  //   id: 'debt_amount_left',
  //   sortable: false,
  //   align: 'left',
  //   disablePadding: false,
  //   label: StringConstants.DEBT_AMOUNT_LEFT,
  // },
  {
    id: 'action',
    sortable: false,
    align: 'center',
    disablePadding: true,
    label: StringConstants.ACTION,
    center: true,
  },
];

export const DebtorTable = (props) => {
  const {
    rows,
    editRow,
    deleteDebtor,
    openAddForm,
    handlePrint,
    calculateTotalDebtAmount,
    calculateTotalPaidAmount,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(AppSettings.debtorTableSettings.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    AppSettings.debtorTableSettings.rowPerPage
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [currentId, setCurrentId] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // Pop-up dialog to confirm on delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const openDeleteDialog = (id) => {
    setDeleteDialogOpen(true);
    setCurrentId(id);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  function calculateDebtAmountLeft(row) {
    const result =
      calculateTotalDebtAmount(row.installments) -
      calculateTotalPaidAmount(row.installments);
    if (result <= 0) return StringConstants.PAID;
    return currencyFormatter.format(result);
  }

  function calculateDownForTable(row) {
    let result = '';
    row.installments.forEach((installment) => {
      if (installment.is_down_payment) {
        result += `${currencyFormatter.format(installment.debt_amount)} `;
      }
    });
    return result;
  }

  function getDownPaymentDate(row) {
    let result = '';
    row.installments.forEach((installment) => {
      if (installment.is_down_payment && installment.is_paid) {
        result += `${formatThaiDate(installment.payment_date)} `;
      }
    });
    if (result) return result;
    return StringConstants.DOWN_NOT_PAID;
  }

  function getInstallmentsDetail(row) {
    const result = {};
    row.installments.forEach((installment) => {
      if (!installment.is_down_payment) {
        const DEBT_AMOUNT = currencyFormatter.format(installment.debt_amount);
        if (!result[DEBT_AMOUNT]) result[DEBT_AMOUNT] = 1;
        else result[DEBT_AMOUNT] += 1;
      }
    });
    let text = '';
    Object.entries(result).forEach((ins) => {
      text += `${ins[0]}x${ins[1]} `;
    });
    return text;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAddForm={openAddForm}
          rows={rows}
          currencyFormatter={currencyFormatter}
          handlePrint={handlePrint}
          isDebtor
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length ? rows.length : 0}
              headCells={headCells}
            />
            <TableBody>
              {rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    isSelected(row.id);
                    const labelId = 'enhanced-table-checkbox-${index}';
                    return (
                      <TableRow hover key={row.id}>
                        <AnnotatedTableCell
                          style={{ witdh: 'auto' }}
                          id={labelId}
                        >
                          {StringConstants.ID_PREFIX + row.id}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row.first_name} {row.last_name}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>{row.company}</AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row.number_plate}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {formatPhoneNumber(row.phone_number)}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {formatThaiDate(row.registered_date)}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row.insurance_type}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {calculateDownForTable(row)}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {getDownPaymentDate(row)}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {getInstallmentsDetail(row)}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row ? formatThaiDate(row.start_pay_date) : '-'}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row.installments.length > 0
                            ? formatThaiDate(
                                row.installments[row.installments.length - 1]
                                  .due_date
                              )
                            : '-'}
                        </AnnotatedTableCell>
                        <AnnotatedTableCell>
                          {row ? formatThaiDate(row.expire_date) : '-'}
                        </AnnotatedTableCell>
                        {/* <AnnotatedTableCell align="right">
                          {calculateDebtAmountLeft(row)}
                        </AnnotatedTableCell> */}

                        <TableCell align="center">
                          <Tooltip title={StringConstants.INFO}>
                            <IconButton
                              onClick={() => {
                                editRow(row);
                              }}
                              className="button muted-button form-button"
                              size="small"
                            >
                              <PersonIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={StringConstants.EDIT}>
                            <IconButton
                              onClick={() =>
                                window.open(
                                  `https://api.wawiinsure.info/admin/api/debtor/${row.id}/change`,
                                  '_blank'
                                )
                              }
                              className="button muted-button form-button"
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={StringConstants.DELETE}>
                            <IconButton
                              onClick={() => openDeleteDialog(row.id)}
                              className="button muted-button form-button"
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <ConfirmDialog
                            open={deleteDialogOpen}
                            title={StringConstants.DELETE_CONFIRMATION}
                            body={StringConstants.DELETE_CONFIRMATION_BODY}
                            confirmText={StringConstants.CONFIRM}
                            handleConfirm={() => deleteDebtor(currentId)}
                            cancelText={StringConstants.CANCEL}
                            handleClose={closeDeleteDialog}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={15}>
                    {StringConstants.DATA_NOT_FOUND}
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={18} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
          component="div"
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={StringConstants.ROWS_PER_PAGE}
          nextIconButtonText={StringConstants.NEXT_PAGE}
          backIconButtonText={StringConstants.PREVIOUS_PAGE}
        />
      </Paper>
    </div>
  );
};

export default DebtorTable;
