export const BACKEND_URL = 'https://api.wawiinsure.info';

export const AppSettings = {
  debtorTableSettings: {
    rowPerPage: 25,
    dense: true,
  },
  installmentTableSettings: {
    rowPerPage: 10,
    dense: true,
  },
  userTableSettings: {
    rowPerPage: 10,
    dense: true,
  },
};

export default AppSettings;
