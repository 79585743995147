import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StringConstants from '../resources/StringConstants';
import ConfirmDialog from '../components/ConfirmDialog';
import AppSettings from '../AppSettings';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'username',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.USERNAME,
  },
  {
    id: 'is_super',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: StringConstants.IS_SUPER,
  },
];

export const UserTable = (props) => {
  const { rows, editRow, deleteUser } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('username');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(AppSettings.userTableSettings.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    AppSettings.userTableSettings.rowPerPage,
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // Pop-up dialog to confirm on delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [currentId, setCurrentId] = useState();

  const openDeleteDialog = (id) => {
    setDeleteDialogOpen(true);
    setCurrentId(id);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAddForm={props.openAddForm}
          rows={rows}
          isUser
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length ? rows.length : 0}
              headCells={headCells}
            />
            <TableBody>
              {rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    isSelected(row.username);
                    const labelId = 'enhanced-table-checkbox-${index}';
                    return (
                      <TableRow hover key={row.username}>
                        <TableCell component="th" id={labelId}>
                          {row.username}
                        </TableCell>
                        <TableCell>
                          {row.is_super ? <CheckIcon /> : <CloseIcon />}
                        </TableCell>
                        {/* <Tooltip title={StringConstants.EDIT}>
                            <IconButton
                              onClick={() => {
                                editRow(row);
                              }}
                              className="button muted-button form-button"
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={StringConstants.DELETE}>
                            <IconButton
                              onClick={() => openDeleteDialog(row.username)}
                              className="button muted-button form-button"
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip> */}
                        {/* <ConfirmDialog
                          open={deleteDialogOpen}
                          title={StringConstants.DELETE_CONFIRMATION}
                          body={StringConstants.DELETE_CONFIRMATION_BODY}
                          confirmText={StringConstants.CONFIRM}
                          handleConfirm={() => props.deleteUser(currentId)}
                          cancelText={StringConstants.CANCEL}
                          handleClose={closeDeleteDialog}
                        /> */}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>
                    {StringConstants.DATA_NOT_FOUND}
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={StringConstants.ROWS_PER_PAGE}
          nextIconButtonText={StringConstants.NEXT_PAGE}
          backIconButtonText={StringConstants.PREVIOUS_PAGE}
        />
      </Paper>
    </div>
  );
};

export default UserTable;
