const StringConstants = {
  ACTION: 'จัดการ',
  ADD_DEBTOR_FORM_TITLE: 'เพิ่มข้อมูลลูกหนี้',
  ADD_NEW_DEBTOR: 'เพิ่มข้อมูลสำเร็จ',
  ADD_NEW_USER: 'เพิ่มผู้ใช้ใหม่',
  ADD_USER_FORM_TITLE: 'เพิ่มผู้ใช้',
  ADD: 'เพิ่ม',
  AMOUNT_TO_PAY_IS_MORE_THAN_DEBT: 'เงินที่จ่าย มากกว่าจำนวนหนี้',
  AMOUNT_TO_PAY_IS_ZERO_OR_NEGATIVE: 'เงินที่จ่าย น้อยกว่าหรือเท่ากับ 0',
  AMOUNT_TO_PAY: 'จำนวนเงินที่จ่าย',
  APP_TITLE: 'ระบบจัดการหนี้สิน',
  BANK_1: 'พี่ต้อย',
  BANK_2: 'พี่นัส',
  CANCEL: 'ยกเลิก',
  CANT_CONNECT_TO_BACKEND:
    'ไม่สามารถติดต่อกับเซิฟเวอร์ได้ในขณะนี้ โปรดติดต่อโปรแกรมเมอร์',
  CASH: 'เงินสด',
  CLEAR_SEARCH: 'เคลียร์การค้นหา',
  COMPANY: 'บริษัท',
  CONFIRM: 'ยืนยัน',
  CREDIT_CARD: 'รูดบัตร',
  CURRENCY: 'บาท',
  DATA_NOT_FOUND: 'ไม่พบรายการ',
  DEBT_AMOUNT_LEFT: 'ค้างจ่าย',
  DEBT_AMOUNT: 'ยอดที่ต้องชำระ',
  DEBTOR_TABLE_TITLE: 'รายการลูกหนี้',
  DCD_CONFIRMATION_BODY: 'โปรดเลือกเดือนและปีที่ต้องการ',
  DCD_CONFIRMATION: 'เลือกเดือนและปีที่ต้องการ',
  DELETE_CONFIRMATION_BODY: 'โปรดยืนยันการทำรายการ',
  DELETE_CONFIRMATION: 'ลบข้อมูล',
  DELETE: 'ลบ',
  DOWN_NOT_PAID: 'ยังไม่จ่าย',
  DOWN_PAYMENT_2: 'เงินดาวน์งวดที่ 2 (ถ้ามี)',
  DOWN_PAYMENT_MONTHLY_SPREAD: 'จำนวนงวดเงินดาวน์',
  DOWN_PAYMENT: 'เงินดาวน์',
  DOWN: 'ดาวน์',
  DUE_DATE: 'วันกำหนดชำระ',
  EDIT_DEBTOR_FORM_TITLE: 'แก้ไขข้อมูลลูกหนี้',
  EDIT_USER_FORM_TITLE: 'แก้ไขข้อมูลผู้ใช้',
  EDIT: 'แก้ไข',
  EMAIL: 'อีเมล',
  ERROR_PAYMENT: 'เกิดข้อผิดพลาดระหว่างจ่ายเงิน',
  EXPIRE_DATE: 'ประกันหมด',
  EXPORT: 'ดาวน์โหลด',
  FILTER_ROW: 'กรองข้อมูล',
  FINISH_PAYING: 'สุดท้าย',
  FIRST_NAME: 'ชื่อ',
  FROM_DATE_IS_LESS: 'กรุณาใส่วันที่ให้ถูกต้อง',
  FROM_DATE: 'ค้นหาจากวันคุ้มครอง',
  FROM_EXPIRE_DATE: 'ค้นหาจากวันหมดประกัน',
  FULL_NAME: 'ชื่อ - นามสกุล',
  ID_PREFIX: 'A',
  ID: 'รหัส',
  INFO: 'ดูข้อมูล',
  INPUT_NAME_OR_LASTNAME: 'ใส่ชื่อหรือนามสกุล',
  INSTALLMENT_MONTHLY_SPREAD: 'จำนวนงวดผ่อนชำระ',
  INSTALLMENT_NUMBER: 'งวดที่',
  INSTALLMENT_TABLE_TITLE: 'ข้อมูลการผ่อนชำระ',
  INSTALLMENTS: 'ค่างวด',
  INSURANCE_TYPE: 'ประเภท',
  IS_DOWN_PAYMENT: 'เงินดาวน์',
  IS_PAID: 'จ่ายแล้ว',
  IS_SUPER: 'เป็น Super admin',
  LAST_NAME: 'นามสกุล',
  LAST_PAY_DATE: 'งวดสุดท้าย',
  LINE_ID: 'LINE',
  LOGIN: 'เข้าสู่ระบบ',
  LOGOUT: 'ออกจากระบบ',
  MODIFY_PAYMENT: 'เปลี่ยนแปลงจำนวนเงิน',
  NEXT_PAGE: 'หน้าถัดไป',
  NOTE: 'เพิ่มเติม',
  NOTIFY_ADDED_NEW_DEBTOR: 'เพิ่มรายการใหม่สำเร็จ',
  NOTIFY_ADDED_NEW_USER: 'เพิ่มผู้ใช้คนใหม่สำเร็จ',
  NOTIFY_DELETE_DEBTOR: 'ลบรายการสำเร็จ',
  NOTIFY_DELETE_USER: 'ลบผู้ใช้สำเร็จ',
  NOTIFY_PAID: 'จ่ายเงินสำเร็จ',
  NOTIFY_SUCCESS_EDIT: 'แก้ไขสำเร็จ',
  NOTIFY_UPDATED_DEBTOR: 'แก้ไขรายการสำเร็จ',
  NOTIFY_UPDATED_USER: 'แก้ไขข้อมูลผู้ใช้สำเร็จ',
  NUMBER_PLATE: 'ทะเบียนรถ',
  PAID_AMOUNT: 'ยอดที่ชำระ',
  PAID_BUT_NOT_ALL: 'ยังจ่ายไม่ครบ',
  PAID_NOT_ALL: 'ยังจ่ายไม่ครบ',
  PAID: 'จ่ายแล้ว',
  PASSWORD_CONFIRM: 'ยืนยันรหัสผ่าน',
  PASSWORD_MISMATCH: 'รหัสผ่านไม่ตรงกัน',
  PASSWORD: 'รหัสผ่าน',
  PAY_ALL: 'จ่ายทั้งหมด',
  PAY_CONFIRMATION_BODY: 'กรุณายืนยันการจ่าย',
  PAY_CONFIRMATION: 'กรุณายืนยันการจ่าย',
  PAY_DOWN: 'จ่ายดาวน์',
  PAY: 'ชำระเงิน',
  PAYMENT_BANK: 'บัญชีธนาคาร',
  PAYMENT_DATE: 'วันที่ชำระ',
  PAYMENT_TYPE: 'จ่ายผ่านช่องทาง',
  PEOPLE_COUNTER: 'คน',
  PHONE_NUMBER_SHOULD_BE_NUMBER:
    'กรุณากรอกตัวเลข 0-9 ในช่องหมายเลขโทรศัพท์\nความยาวไม่เกิน 10 ตัว',
  PHONE_NUMBER: 'โทร.',
  PLEASE_FILL_ALL_REQUIREMENT: 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน',
  PLEASE_INPUT_REGISTERED_DATE: 'กรุณาใส่วันที่ซื้อประกันก่อน',
  PLEASE_LOGIN: 'เข้าสู่ระบบ',
  PLUS_A_YEAR: 'ปีหน้า',
  PREVIOUS_PAGE: 'หน้าก่อน',
  PRINT_TABLE_A: '1. พิมพ์รายการลูกหนี้และค่างวด',
  PRINT_TABLE_B: '2. พิมพ์รายการลูกหนี้ที่ค้างชำระเกินกำหนด',
  PRINT_TABLE_C: '3. พิมพ์รายชื่อผู้ซื้อเบี้ยประกันภัย',
  PRINT_TABLE_D: '4. พิมพ์รายการเรียงตามตัวอักษร',
  PRINT_TABLE_E: '5. พิมพ์รายการชำระค่างวดหมด',
  PRINT_TABLE_F: '6. พิมพ์รายการลูกค้าที่ประกันหมด',
  PRINT: 'พิมพ์',
  REGISTERED_DATE: 'วันคุ้มครอง',
  ROW: 'แถว',
  ROWS_PER_PAGE: 'จำนวนแถวต่อหน้า',
  SEARCH_AND_FILTER: 'ค้นหา',
  SEARCH_BY_DATE: 'ค้นหาด้วยวันที่',
  SEARCH_BY_NAME: 'ค้นหาด้วยชื่อ - นามสกุล หรือรหัส',
  SEARCH_BY_ID_FROM: 'ค้นหาจากรหัสที่',
  SEARCH_BY_ID_TO: 'ค้นหาถึงรหัสที่',
  SEARCH: 'ค้นหา',
  SELECT: 'เลือก',
  SHOW_OVERDUES: 'แสดงรายการค้างชำระ',
  SHOW_TABLE: 'แสดงตาราง',
  SIGNED_IN_BY: 'เข้าสู่ระบบโดย',
  START_PAY_DATE: 'เริ่มจ่าย',
  START_PAYING_INSTALLMENT: 'เริ่มผ่อน',
  START_PAYING: 'เริ่มจ่าย',
  STATUS: 'สถานะ',
  TO_DATE: 'ค้นหาถึงวันคุ้มครอง',
  TO_EXPIRE_DATE: 'ค้นหาถึงวันหมดประกัน',
  TODAY: 'วันนี้',
  TOGGLE_DENSE_TABLE: 'แสดงข้อมูลแบบหนาแน่น',
  TOTAL_DEBT_AMOUNT: 'ยอดหนี้สินรวม',
  TOTAL_DEBT_SHOULD_BE_MORE_THAN_DOWN_PAYMENT:
    'หนี้สินรวม ต้องไม่น้อยกว่าเงินดาวน์',
  TOTAL_PAID_AMOUNT: 'ยอดชำระรวม',
  TOTAL_PAID: 'จ่ายแล้ว',
  TOTAL_UNPAID: 'ค้างจ่าย',
  TOTALLY: 'เป็นเงิน',
  TRANSFER: 'โอน',
  UNPAID: 'ยังไม่จ่าย',
  USER_DASHBOARD: 'จัดการผ่านระบบหลังบ้าน',
  USER_DASHBOARD_ADD: 'เพิ่มผู้ใช้ใหม่',
  USER_TABLE_TITLE: 'ข้อมูลผู้ใช้',
  USER: 'ผู้ใช้',
  USERNAME: 'ชื่อผู้ใช้',
  WELCOME_USER: 'ยินดีต้อนรับ',
  WRONG_PHONE_NUMBER_FORMAT: 'เบอร์โทรศัพท์ไม่ถูกต้อง โปรดแก้ไข',
  WRONG_USER_OR_PASSWORD: 'อีเมล หรือ รหัสผ่าน ไม่ถูกต้อง!',
  OVERDUE_DEBTORS: 'ค้างเกิน',
  VIEW_DATA: 'ดูข้อมูล',
  EXPIRING_DEBTORS: 'ประกันหมดเดือนนี้',
  NO_MORE_INS_DEBTORS: 'ค่างวดหมดเดือนนี้',
  FROM_INS_DUE_DATE: 'ค้นหาจากวันค่างวด',
  TO_INS_DUE_DATE: 'ค้นหาถึงวันค่างวด',
};

export default StringConstants;
