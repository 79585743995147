import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function DateChooserDialog(props) {
  const {
    id,
    open,
    title,
    body,
    confirmText,
    handleConfirm,
    cancelText,
    handleClose,
  } = props;

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 5;
    var min = max - 50;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
          <br />
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="month-label">เดือน</InputLabel>
              <Select
                labelId="month-label"
                id="month-select"
                value={month}
                onChange={(event) => setMonth(event.target.value)}
                width="100%"
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="year-label">ปี</InputLabel>
              <Select
                labelId="year-label"
                id="year-select"
                value={year}
                onChange={(event) => setYear(event.target.value)}
                width="100%"
              >
                {generateArrayOfYears().map((year) => (
                  <MenuItem value={year}>
                    คศ. {year} (พศ. {year + 543})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {cancelText}
          </Button>
          <Button
            onClick={() => {
              handleConfirm(month, year);
              setMonth(null);
              setYear(null);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
