import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import StringConstants from '../resources/StringConstants';
import { range } from '../Utils';

const FormInstallmentFields = (props) => (
  <Grid
    container
    spacing={2}
    direction="row"
    justify="flex-start"
    className="form-input-field"
  >
    <Grid item xs={12} md={12} lg={6}>
      <TextField
        type="number"
        name="totalDebtAmount"
        label={StringConstants.TOTAL_DEBT_AMOUNT}
        onChange={(event) => {
          props.setTotalDebtAmount(parseFloat(event.target.value));
        }}
        fullWidth
        variant="outlined"
        required
      />
    </Grid>
    <Grid item xs={12} md={12} lg={6}>
      <TextField
        label={StringConstants.INSTALLMENT_MONTHLY_SPREAD}
        id="monthlySpread"
        onChange={(event) => {
          props.setMonthlySpread(parseInt(event.target.value));
        }}
        select
        fullWidth
        variant="outlined"
        required
      >
        {range(12, 1).map((i) => (
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
    <Grid item xs={12} md={12} lg={6}>
      <TextField
        type="number"
        name="downPaymentAmount"
        label={StringConstants.DOWN_PAYMENT}
        onChange={(event) => {
          props.setDownPaymentAmount(parseFloat(event.target.value));
        }}
        fullWidth
        variant="outlined"
        required
      />
    </Grid>
    <Grid item xs={12} md={12} lg={6}>
      <TextField
        type="number"
        name="downPaymentAmount2"
        label={StringConstants.DOWN_PAYMENT_2}
        onChange={(event) => {
          props.setDownPaymentAmount2(parseFloat(event.target.value));
        }}
        fullWidth
        variant="outlined"
      />
    </Grid>
    <Grid item xs={12} md={12} lg={6}>
      <TextField
        label={StringConstants.START_PAYING_INSTALLMENT}
        id="start_pay_date"
        onChange={props.handleStartPayDateChange}
        type="date"
        fullWidth
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </Grid>
);

export default FormInstallmentFields;
