import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import InstallmentTable from '../tables/InstallmentTable';
import { Transition } from './DialogConfig';
import FormInputFields from './FormInputFields';
import StringConstants from '../resources/StringConstants';

const EditDebtorForm = (props) => {
  const {
    updateDebtor,
    currentDebtor,
    editing,
    closeEditForm,
    currencyFormatter,
  } = props;

  const [debtor, setDebtor] = useState(currentDebtor);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setDebtor({ ...debtor, [name]: value });
  };

  useEffect(() => {
    setDebtor(currentDebtor);
  }, [props]);

  return (
    <Dialog
      open={editing}
      onClose={closeEditForm}
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateDebtor(debtor.id, debtor);
          closeEditForm();
        }}
      >
        <DialogTitle id="form-dialog-title">
          {StringConstants.EDIT_DEBTOR_FORM_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {StringConstants.PLEASE_FILL_ALL_REQUIREMENT}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <FormInputFields
                onChange={handleInputChange}
                debtor={debtor}
                isAdd={false}
                setDebtor={setDebtor}
              />
            </Grid>
            <Grid item lg={12}>
              <InstallmentTable
                rows={currentDebtor.installments}
                currencyFormatter={currencyFormatter}
                updateInstallment={props.updateInstallment}
                deleteInstallment={props.deleteInstallment}
                handleOpenNotify={props.handleOpenNotify}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditForm} color="secondary">
            {StringConstants.CANCEL}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {StringConstants.CONFIRM}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditDebtorForm;
