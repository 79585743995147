/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, List, ListItem } from '@material-ui/core';

const InstallmentPreview = (props) => {
  const {
    debtor,
    currencyFormatter,
    totalDebtAmount,
    downPaymentAmount,
    downPaymentAmount2,
    monthlySpread,
    startPayingDate,
  } = props;

  useEffect(() => {}, [props]);

  // Render component
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="flex-start"
      className="form-input-field"
    >
      <Grid item xs={12}>
        <Typography variant="h4">สรุป</Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              {`หนี้รวม: ${currencyFormatter.format(totalDebtAmount)} บาท`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {`เงินดาวน์งวดที่ 1: ${currencyFormatter.format(
                downPaymentAmount,
              )} บาท`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {`เงินดาวน์งวดที่ 2: ${currencyFormatter.format(
                downPaymentAmount2,
              )} บาท`}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              {`ผ่อน ${monthlySpread} เดือน ตกเดือนละ ${currencyFormatter.format(
                (totalDebtAmount - (downPaymentAmount + downPaymentAmount2))
                  / monthlySpread,
              )} บาท`}
            </Typography>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default InstallmentPreview;
