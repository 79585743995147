import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as passwordHash from 'password-hash';
import StringConstants from '../resources/StringConstants';
import { Transition } from './DialogConfig';

const EditUserForm = (props) => {
  const {
    currentRow, closeForm, updateRow, editing,
  } = props;

  const [row, setRow] = useState(currentRow);
  const [checked, setChecked] = useState(currentRow.is_super);

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    const hash = passwordHash.generate(value);
    setRow({ ...row, [name]: hash });
  };

  const handleChecked = (event) => {
    const { name, checked } = event.target;
    setRow({ ...row, [name]: checked });
  };

  return (
    <Dialog
      open={editing}
      onClose={closeForm}
      TransitionComponent={Transition}
      maxWidth="lg"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (row.password) {
            updateRow(row);
            closeForm();
          } else {
            alert(StringConstants.PLEASE_FILL_ALL_REQUIREMENT);
          }
        }}
      >
        <DialogTitle id="form-dialog-title">
          {StringConstants.EDIT_USER_FORM_TITLE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {StringConstants.PLEASE_FILL_ALL_REQUIREMENT}
          </DialogContentText>

          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            className="form-input-field"
          >
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                type="password"
                name="password"
                label={StringConstants.PASSWORD}
                onChange={handlePasswordChange}
                fullWidth
                variant="outlined"
                required
                autoComplete={false}
              />
            </Grid>
            <Grid item xs md lg>
              <FormControlLabel
                control={(
                  <Checkbox
                    id="is_super"
                    onChange={handleChecked}
                    name="is_super"
                    color="primary"
                  />
                )}
                label={StringConstants.IS_SUPER}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForm} color="secondary">
            {StringConstants.CANCEL}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {StringConstants.CONFIRM}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserForm;
