/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import StringConstants from '../resources/StringConstants';

export default function SearchBox(props) {
  const { setValue, search } = props;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        search();
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <TextField
            label={StringConstants.SEARCH_BY_NAME}
            id="searchName"
            onChange={(event) => {
              setValue(event.target.value);
            }}
            fullWidth
            variant="outlined"
            autoFocus
            margin="dense"
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={StringConstants.SEARCH}>
            <IconButton type="submit" color="primary">
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}
